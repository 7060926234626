@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);

/* 12px 14px 16px */
.loading{
  text-align: center;
  vertical-align: middle;
}

.App {
  text-align: center;
}

.container{
  font-family: "Open Sans", 'sans-serif';
  color: #2b2b2b;
  max-width: 900px;
  margin: auto;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 20px -30px 40px rgba(0, 0, 0, 0.1),
  -20px -30px 40px rgba(0, 0, 0, 0.1);
}

.container-ControlRoom{
  font-family: "Open Sans", 'sans-serif';
  color: #2b2b2b;
  max-width: 1600px;
  margin: auto;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 20px -30px 40px rgba(0, 0, 0, 0.1),
  -20px -30px 40px rgba(0, 0, 0, 0.1);
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

#timelineContainer{
  padding-top: 60px;
}

#divSpinnerBio{
  width: 100%;
  position: absolute;
  top: 0px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divSpinnerTimeline{
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#emptySpan{
  font-size: 12px;
  font-weight: 600;
  color: #aaaaaa;
}

#div404{
  width: 100%;
  position: absolute;
  top: 0px;
  bottom: 0;
  background-color:#222222;
  text-align: center;
  padding-top: 70px;
}

.span404{
  display: block;
  color: white;
  font-weight: 600;
  font-size: 32px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.container_story{
    width: 100vw; /* Set the width to a percentage of the viewport width */
    height: 100vw; /* Set the height equal to the width */
    max-width: 900px;
    max-height: 900px;
    margin: auto;
    background-color: #F3F4F2; /* Just for demonstration */
    display: flex; /* Enables Flexbox */
    justify-content: center; /* Horizontally centers the child in the container */
    align-items: center; /* Vertically centers the child in the container */
}

#openButton{
  text-align: center;
  margin-top: -4px;
  margin-bottom: 15px;
}
#imageBtnOpen{
  width: 140px;
}
.message_story{
  margin-bottom: 20px;
}