.popup_outer{
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  height: vh;
  margin: auto;
  border-radius: 0px;
  background: rgba(0, 0, 0, 0.6);
}

.popup_inner{
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  border-radius: 20px;
  background: white;
}

.contentPopup{
  position: relative;
  left: 10%;
  right: 10%;
  top: 10%;
  bottom: 10%;
  margin: auto;
}

#SignInButtonPopup{
  width: 80%;
  height: 40px;
  margin-top: 20px;
  border-color: #2196F3;
  color: dodgerblue;
}

#SignInButtonPopup:hover {
  background: #2196F3;
  color: white;
}


.loginField{
  display: table;
  margin-bottom: 20px;
  width: 80%;
}

#labelField{
  display: table-cell;
  width: 20%;
  height: auto;
  color:lightgrey;
}

#inputField{
  display: table-cell;
  width: 100%;
  height: 30px;
}

#titleLogin{
  width: 80%;
  text-align: center;
  margin-bottom: 40px;
}

#messageDiv{
  width: 80%;
  height: 40px;
  margin-top: 20px;
  text-align:center;
}

#message{
  width: 80%;
  height: 40px;
  color: red;
}

.logInSpinner{
  width: auto;
  height: auto;
  position: absolute;
  left: 38%;
  right: 38%;
  margin: auto;
}
