.containerProfile{
  padding-top: 60px;
  align-items: center;
  padding-bottom: 10px;
}

section, #bioContainer{
  color: #2b2b2b;
}

#containerProfile{
  display: flex;
  padding: 20px;
  align-items: center;
}

#sideTexts{
  margin-left: 20px;
  flex-grow: 1;
  font-size: 1rem;
  color: #2b2b2b;
}

.lightgrey{
  color: #aaaaaa;
  font-size: 12px;
  font-weight: 600;
}

.numbersDetail{
  font-weight: 600;
  font-size: 14px;
}

#numTexts{
  display: flex;
}

#follower{
  margin-left: 10px;
}

#following{
  margin-left: 10px;
}

#follower p, #following p, .profileNumers p{
  margin-top: 0px;
  margin-bottom: 0px;
}

#bioContainer{
  padding-left: 20px;
  padding-right: 20px;
}

#bioText{
  margin: 0px;
  font-size: 14px;
  white-space: pre-line;
}

#imageAvatar{
  width: 90px;
  height: 90px;

  border-radius: 50%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #aaaaaa4c;
}

#userName{
  font-weight: 600;
  margin-bottom: 16px;
  font-size: 16px;
}

#spanName{
  color: #aaaaaa;
  font-size: 12px;
}

#userName p{
  margin: 0;
}

#battleTypeSwitch{
  display: flex;
  flex-direction: row;
  margin:auto;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  vertical-align: middle;
}

#timeline{
  flex: 50;
}

#favorite{
  flex: 50;
}

#divider{
  background: lightgrey;
  height: 6px;
  width: 1px;
  margin: auto;
}

#imageTimeline{
  width:10%;
}

#imageFavorite{
  width: 10%;
}

#openButton{
  text-align: center;
  margin-top: -4px;
  margin-bottom: 15px;
}

#imageBtnOpen{
  width: 140px;
}

#textOpen{
  background: #53D76A;
  color: white;
  padding: 8px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
}
