.popup_outer{
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  height: vh;
  margin: auto;
  border-radius: 0px;
  background: rgba(0, 0, 0, 0.6);
}

.popup_inner{
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom:25%;
  padding-top: 1vw;
  padding-bottom: 1vw;
  margin: auto;
  border-radius: 1px;
  background: white;
}

.contentPopup{
  position: absolute;
  left: 25%;
  right: 25%;
  top:25%;
  border: 25%;
  padding-top: 1vw;
  padding-bottom: 1vw;
  margin: auto;
}

#SignInButtonPopup{
  width: 80%;
  height: 2vw;
  margin-top: 1vw;
  border-color: #2196F3;
  color: dodgerblue;
}

#SignInButtonPopup:hover {
  background: #2196F3;
  color: white;
}


.loginField{
  display: table;
  margin-bottom: 2vw;
  width: 80%;
}

#inputField{
  display: table-cell;
  width: 100%;
  line-height: 10px;
  padding: 2px;
}

#titleLogin{
  width: auto;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2vw;
  font-size: 4vw;
}

#messageDiv{
  width: 80%;
  height: 2vw;
  margin-top: 10px;
  text-align:center;
}

#message{
  width: 80%;
  height: 1vw;
}

.logInSpinner{
  width: auto;
  height: auto;
  position: absolute;
  left: 38%;
  right: 38%;
  margin: auto;
}


@media (min-width:900px) {
  .popup_outer{
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    height: vh;
    margin: auto;
    border-radius: 0px;
    background: rgba(0, 0, 0, 0.6);
  }

  .popup_inner{
    position: absolute;
    left: 4%;
    right: 4%;
    top:4%;
    bottom: 4%;
    padding-top: 1vw;
    padding-bottom: 1vw;
    margin: auto;
    border-radius: 1px;
    background: white;
  }

  .contentPopup{
    position: absolute;
    left: 4%;
    right: 4%;
    padding-top: 1vw;
    padding-bottom: 1vw;
    margin: auto;
  }

  #titleLogin{
    width: 80%;
    margin-bottom: 10px;
    font-size: 2vw;
  }

  #labelField{
    display: table-cell;
    width: 20%;
    height: auto;
    color:lightgrey;
    font-size: 1vw
  }

  #inputField{
    display: table-cell;
    width: 100%;
    line-height: 10px;
    padding: 2px;
  }

  #SignInButtonPopup{
    width: 80%;
    height: 1vw;
    margin-top: 1vw;
    padding: 0px;
    border-color: #2196F3;
    color: dodgerblue;
  }

  #messageDiv{
    width: 80%;
    height: 4px;
    margin-top: 10px;
    text-align:center;
  }

  #message{
    width: 80%;
    height: 4px;
  }

  @media (min-width:320px) {
    .contentPopup{
      display: inline;
      position: absolute;
      left: 4%;
      right: 4%;
      top:4%;
      bottom: 4%;
      padding-top: 2px;
      padding-bottom: 2px;
      margin: auto;
    }

  }

}
