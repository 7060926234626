.banner{
  position: fixed;
  z-index: 1000;
  left: 0;
  top:0;
  right: 0;
  max-width: 900px;
  background: #222222;
  margin: auto;
  display: flex;
  flex-direction: row;
  margin:auto;
  height: 60px;
}

#battleAppLogo{
  flex-grow: 50;
  text-align: left;
}

#storeLogo{
  flex-grow: 50;
  text-align: right;
}

#imgLogo{
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

#imgStore{
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}
