#headerContainer{
  display: flex;
  flex-direction: row;
  margin:auto;
  padding-top: 70px;
  width: 100%;
  text-align: center;
  vertical-align: middle;
}

#divLeftUser >*{
  vertical-align: middle;
}

#divLeftUser{
  margin-bottom: 0px;
}

#leftCell{
  flex: 50;
  text-align: left;
  padding-bottom: 10px;
}

#rightHalf{
  flex: 50;
  text-align: right;
}

#leftCell > *{
  vertical-align: middle;
}

#leftAvatar{
  margin-left: 10px;
}

#userNameLeft{
  margin-left: 10px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}

#divRightUser >*{
  vertical-align: middle;
}

#rightCell > *{
  vertical-align: middle;
}

#rightAvatar{
  margin-right: 10px;
}

#userNameRight{
  margin-right: 10px;
  margin-bottom: 30px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}

/* avatar image **/
.avatarHeader{
  width: 34px;
  height: 34px;
  border-radius: 17px;
  border: 0.1px solid rgba(170, 170, 170, 10);
}

#vs{
  height: 16px;
  vertical-align: middle;
}

#divVS{
  margin: auto;
  padding-bottom: 10px;
}

.link{
  text-decoration: none;
  color: #2b2b2b;
}

#divBattle{
  text-align: right;
  vertical-align: middle;
  padding-top: 0px;
  padding-right: 10px;
}

#buttonBattle{
  height: 34px;
}

.link >*{
  vertical-align: middle;
}
