#palyer{
  width: 100%;
  height: 100%;
}

#imgPost{
  width: 100%;
  max-width: 900px;
  margin: auto;
}
