.grid-ControlRoom{
    padding-top: 8vw;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 1px;
    grid-row-gap: 1px;
}

.battlecell-ControlRoom{
  position: relative;
  width: 100%;
  height: 18vw;
}

.gifImage-ControlRoom{
  width: 100%;
  object-fit: cover;
  height: 16vw;
  background-color: #FAF0E6;
  position: relative;
}

.gifImageSmall{
  width: 16vw;
  height: 16vw;
  position: relative;
  margin-top: 2vw;
}

.imageSelect{
  width: 2vw;
  height: 2vw;
  left: 16.2vw;
  bottom: 10vw;
  position: relative;
}

.typeNegative{
  position: absolute;
  height: 16vw;
  width: 100%;
  right: 0%;
  top: 0%;
}

.interactions {
  padding-top: 60px;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#typeVideo{
  position: absolute;
  right: 5%;
  top: 3%;
  width: 16px;
  height: auto;
}

#typePhoto{
  position: absolute;
  right: 5%;
  top: 5%;
  width: 16px;
  height: auto;
}

#star{
  position: absolute;
  right: 10px;
  bottom: 5%;
  width: 12px;
  height: auto;
}

#challengeIcon{
  width: 12px;
  height: auto;
}

#challengeContainer{
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0%;
  padding-bottom: 4px;
  background-color: #aaaaaa4c;
  text-align: center;
}

#chanllengeVote{
  color: white;
  margin-left: 6px;
  font-size: 12px;
  font-weight: 600;
}

@media (min-width:900px) {
  .battlecell-ControlRoom{
    position: relative;
    width: 100%;
    height: 18vw;
  }

  .gifImageSmall{
    width: 16vw;
    height: 16vw;
    position: relative;
  }

  .imageSelect{
    width: 2vw;
    height: 2vw;
    left: 16.2vw;
    bottom: 9vw;
    position: relative;
  }

  .gifImage-ControlRoom{
    width: 100%;
    object-fit: cover;
    height: 16vw;
    background-color: #F3F4F2;
    position: relative;
  }
}
