.bannerControlRoom{
  position: fixed;
  z-index: 1000;
  left: 0;
  top:0;
  right: 0;
  height: 8vw;
  max-width: 1600px;
  background: white;
}

#controlRoomLogo{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2vw;
  margin-bottom: auto;
  width: auto;
  height: 3.2vw;
}

#submitRefresh{
  position: absolute;
  width: auto;
  height: 4vw;
  right: 2vw;
  top: 2vw;
}
