.grid{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1px;
    grid-row-gap: 1px;
}

.battlecell{
  position: relative;
  width: 100%;
  height: 33vw;
}

.starContainer{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 50%;
}

.starImage{
  width:16%;
  height: 16%;
  top: 42%;
  left: 42%;
  position: absolute;
}

.gifImageStar{
  width: 100%;
  object-fit: cover;
  height: 16.5vw;
  background-color: #FAF0E6;
  position: relative;
}

.interactions {
  width: 100%;
  height: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gifImage{
  width: 100%;
  object-fit: cover;
  height: 33vw;
  background-color: #FAF0E6;
  position: relative;
}

#typeVideo{
  position: absolute;
  right: 5%;
  top: 3%;
  width: 16px;
  height: auto;
}

#typePhoto{
  position: absolute;
  right: 5%;
  top: 5%;
  width: 16px;
  height: auto;
}

#star{
  position: absolute;
  right: 10px;
  bottom: 5%;
  width: 12px;
  height: auto;
}

#challengeIcon{
  width: 12px;
  height: auto;
}

#challengeContainer{
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0%;
  padding-bottom: 4px;
  background-color: #aaaaaa4c;
  text-align: center;
}

#chanllengeVote{
  color: white;
  margin-left: 6px;
  font-size: 12px;
  font-weight: 600;
}

@media (min-width:900px) {
  .battlecell{
    position: relative;
    width: 100%;
    height: 300px;
  }

  .gifImage{
    width: 100%;
    object-fit: cover;
    height: 300px;
    background-color: #F3F4F2;
    position: relative;
  }

  .gifImageStar{
    width: 100%;
    object-fit: cover;
    height: 150px;
    background-color: #F3F4F2;
    position: relative;
  }
}
