#containerFooter{
  padding-bottom: 30px;
}

.percentageBar{
  width: auto;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 20px;
  margin-right: 20px;
  height: auto;
  display: flex;
  flex-direction: row;
}

.red{
  flex-grow: 50;
  height: 4px;
  background: #F12E24;
  margin-left: 2px;

}
.blue{
  height: 4px;
  flex-grow: 50;
  margin-right: 2px;
  background: #145291;
}

.percentageNumContainer{
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  color: #aaaaaa;
  font-size: 12px;
}

.percentSemi{
  font-weight: 600;
  font-size: 12px;
  width: 40px;
}

#percentLeft{
  text-align: left;
}

#percentRight{
  text-align: right;
}

#totalVotes{
  margin-left: auto;
  margin-right: auto;
  color: #2b2b2b;
  font-weight: 600;
  font-size: 12px;
}

#caption1Container{
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 0px;
  font-size: 14px;
  white-space: pre-line;
}

#caption2Container{
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  font-size: 14px;
  white-space: pre-line;
}

#voteButton{
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

#textVote{
  background: #53D76A;
  color: white;
  padding: 8px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
}

#imageBtnVote{
  width: 140px;
}

#link{
  text-decoration: none;
}

.userNameCaption{
  font-weight: 600;
  font-size: 14px;
}
